<template>
  <ValidationObserver v-slot="{handleSubmit}">
    <page-loading :loading="initializing" />

    <m-card v-if="!initializing" :title="$t('users.editUserRoles')">
      <v-row dense>
        <v-col cols="12">
          <auto-complete
            v-model="model.roleIds"
            :label="$t('users.roles')"
            :search="roleSearch"
            :select="selectRoles"
            multiple
            item-text="name"
            item-value="id"
          />
        </v-col>
      </v-row>

      <template #actions>
        <page-footer edit @submit="handleSubmit(submit)" :loading="progressing" />
      </template>
    </m-card>
  </ValidationObserver>
</template>

<script>
import {mapState} from 'vuex'
export default {
  name: 'Edit',
  props: ['id'],
  data() {
    return {
      roles: [],
      branchInfo: {},
      model: {
        roleIds: [],
        userId: +this.id
      }
    }
  },
  async created() {
    try {
      await this.getAllRoles()
      const userInfo = await this.$api.org.users.getInfoById(this.id)
      const breadcrumbs = [
        {text: this.$t('nav.users'), to: '/org/users'},
        {text: this.$t('users.userRoles', {name: userInfo.fullName}), disabled: true}
      ]
      this.$setBreadcrumbs(breadcrumbs)
      this.initializing = false
    } catch (error) {
      this.$showError(error)
    }
  },
  computed: {
    ...mapState(['currentOrgId'])
  },
  methods: {
    selectRoles() {
      const roleIds = this.roles.map((item) => item.id)
      this.model.roleIds = roleIds
      return Promise.resolve(this.roles)
    },
    roleSearch(filter = {}) {
      return this.$api.org.roles.getAllInfo(filter)
    },
    getAllRoles() {
      const filter = {
        organizationId: this.currentOrgId || null
      }
      return this.$api.org.users.getRoles(this.id, filter).then((res) => {
        this.roles = res
      })
    },
    submit() {
      return this.$api.org.users
        .updateRoles(this.model)
        .then(() => {
          this.$showSuccess(this.$t('users.userRolesUpdated'))
        })
        .catch((err) => {
          this.$showError(err)
        })
    }
  }
}
</script>
